<template>
  <div id="manuscript-list">
    <!-- app drawer -->

    <v-dialog
      v-model="isFormActive"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 700 : '100%'"
    >
      <manuscript-form

        v-model="item"
        :is-form-active="isFormActive"
        :pay-options="payOptions"
        :plan-options="planOptions"
        @refetch-data="fetchItems"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{ item.id ? $t('Modifier'): $t('Ajouter un manuscrit') }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isFormActive = !isFormActive"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:form-cancel>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="isFormActive = !isFormActive"
          >
            {{ $t('Annuler') }}
          </v-btn>
        </template>
      </manuscript-form>
    </v-dialog>
    <v-dialog

      v-model="isPropositionActive"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 400 : '100%'"
    >
      <manuscript-proposition-form

        :is-form-active="isPropositionActive"
        :selected="selectedRows"
        @refetch-data="fetchItems"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{ $t('Modifier la date de la proposition')}}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isPropositionActive = !isPropositionActive"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:form-cancel>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="isPropositionActive = !isPropositionActive"
          >
            {{ $t('Annuler') }}
          </v-btn>
        </template>
      </manuscript-proposition-form>
    </v-dialog>
    <v-dialog
      v-model="isRelanceActive"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 400 : '100%'"
    >
      <manuscript-date-relance-form

        :is-form-active="isRelanceActive"
        :selected="selectedRows"
        @refetch-data="fetchItems"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{ $t('Modifier la date de date de relance')}}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isRelanceActive = !isRelanceActive"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:form-cancel>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="isRelanceActive = !isRelanceActive"
          >
            {{ $t('Annuler') }}
          </v-btn>
        </template>
      </manuscript-date-relance-form>
    </v-dialog>
    <v-dialog

      v-model="signatureDialog"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 400 : '100%'"
    >
      <signature-form

        v-model="item"
        :is-form-active="signatureDialog"
        :pay-options="payOptions"
        :plan-options="planOptions"
        @refetch-data="fetchItems"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{ item.id ? $t('Modifier'): $t('Ajouter un manuscrit') }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="signatureDialog = !signatureDialog"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:form-cancel>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="signatureDialog = !signatureDialog"
          >
            {{ $t('Annuler') }}
          </v-btn>
        </template>
      </signature-form>
    </v-dialog>
    <v-dialog
      v-model="accordDialog"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 400 : '100%'"
    >
      <accord-form
        v-model="item"
        :is-form-active="accordDialog"
        :pay-options="payOptions"
        :plan-options="planOptions"
        @refetch-data="fetchItems"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{ $t('Accord Signature') }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="accordDialog = !accordDialog"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:form-cancel>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="accordDialog = !accordDialog"
          >
            {{ $t('Annuler') }}
          </v-btn>
        </template>
      </accord-form>
    </v-dialog>

    <!-- list filters -->
    <v-card>
      <v-card-title>
        {{ $t("Manuscrits") }}<v-chip outlined class="ml-2">{{ totalItems }}</v-chip>
        <v-spacer />
        <v-dialog
          v-model="isFormImportActive"
          touchless
          :right="!$vuetify.rtl"
          :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="$can('import','Manuscript')"
              v-bind="attrs"
              v-on="on"
              color="info"
              class=" me-3"
              @click.stop="isFormImportActive =  true"
            ><v-icon>{{ icons.mdiImport }}</v-icon>
              <span>{{ $t("Attribuer") }}</span>
            </v-btn>
          </template>
          <manuscript-import-form
            v-model="item"
            :is-form-active="isFormImportActive"
            @refetch-data="fetchItems"
          >
            <template v-slot:form-title>
              <span class="font-weight-semibold text-base text--primary">{{ $t('Quotation manuelle des manuscrits aux évaluateurs') }}</span>
              <v-spacer />
              <v-spacer></v-spacer>
              <v-btn
                icon
                small
                @click="isFormImportActive = !isFormImportActive"
              >
                <v-icon size="22">
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </template>
          </manuscript-import-form>
        </v-dialog>
        <export-excel
          v-if="$can('download','Manuscript')"
          :options="downloadObject"
          @refetch-data="fetchItems"
        />
        <v-btn
          v-if="$can('edit','Manuscript')"
          color="primary"
          class=" me-3"
          @click.stop="openDialog(false)"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>{{ $t("Ajouter un manuscrit") }}</span>
        </v-btn>
        <v-btn
          v-if="$can('edit_proposal_date','Manuscript')"
          color="primary"
          class="me-3"
          :disabled="!selectedRows.length"
          @click.stop="openPropositionDialog(false)"
        >
          <v-icon>{{ icons.mdiCalendar }}</v-icon>
          <span>{{ $t("proposition") }}</span>
        </v-btn>
        <v-btn
          v-if="$can('edit_relaunch_date','Manuscript')"
          color="primary"
          class="me-3"
          :disabled="!selectedRows.length"
          @click.stop="openRelanceDialog(false)"
        >
          <v-icon>{{ icons.mdiCalendar }}</v-icon>
          <span>{{ $t("Relance") }}</span>
        </v-btn>
      </v-card-title>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        class="fixed-table manuscripts-table"
        :headers="filters"
        :items="items"
        :options.sync="options"
        :page.sync="page"
        :server-items-length="totalItems"
        :loading="loading"
        :loading-text="$t('Chargement des éléments')"
        :no-data-text="$t('Pas de données')"
        :no-results-text="$t('Aucun résultat')"
        hide-default-footer
        :show-select="true"
        dense fixed-header :height="tableHeight"
        @page-count="pageCount = $event"
      >
        <template #body.prepend="{ items, headers }">
          <header-filters :headers="filters" :show-select="true"/>
        </template>
        <template #item="options">
          <Row
            :options="options"
            :show-select="true"
            completed-field="publication.state"
            :config="{ url: '/manuscripts/action/', author: 'author', resource: 'Manuscript'}"
            @refetch-data="fetchItems"
            @saved="saved"
            @select="select"
          >
        <!-- name -->
        <template #[`item.author.full_name`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              v-if="item.author"
              :color="item.author.avatar ? '' : 'primary'"
              :class="item.author.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="30"
            >
              <v-img
                v-if="item.author.avatar"
                :lazy-src="require(`@/assets/images/avatars/2.png`)"
                :src="item.author.avatar || require(`@/assets/images/avatars/2.png`)"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.author.full_name) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3" v-if="item.author">
              <router-link
                :to="{ name : 'author-view', params : { id : item.author_id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.author.full_name }}
              </router-link>
              <!--              <small>@{{ item.manuscriptname }}</small>-->
            </div>
          </div>
        </template>
        <template #[`item.author.old_author`]="{item}">
          <v-icon
            v-if="item.author && item.author.old_author"
            small
            color="success"
          >
            {{ icons.mdiCheckCircle }}
          </v-icon>
        </template>
        <template #[`item.images_couleur`]="{item}">
          <span
            v-if="item.images_couleur != null"
          >
            {{ item.images_couleur? 'Oui':'Non' }}
          </span>
        </template>

        <template #[`item.publish`]="{item}">
          <div class="text-center" v-if="item.publish">
            {{ item.publication? item.publication.id : ''}}
          </div>
        </template>
        <template #[`item.public_price`]="{item}">
          <span>{{ item.public_price.toFixed(2)}}</span>
        </template>
            <template #[`item.author.email`]="{item}">
              {{ item.author && item.author.email?item.author.email.join(', '):'' }}
            </template>
        <!-- pay -->
        <template #[`item.country`]="{item}">
          <div
            v-if="item.country"
            class="d-flex align-center"
          >
            <!--            <v-avatar
              size="30"
              :color="resolveManuscriptRoleVariant(item.pay)"
              :class="`v-avatar-light-bg ${resolveManuscriptRoleVariant(item.pay)}&#45;&#45;text me-3`"
            >
              <v-icon
                size="18"
                :color="resolveManuscriptRoleVariant(item.pay.name)"
              >
                {{ resolveManuscriptRoleIcon(item.pay.name) }}
              </v-icon>
            </v-avatar>-->
            <span class="text-capitalize">{{ item.country.name }}</span>
          </div>
        </template>

        <template #[`item.proposal_date`]="{item}">
          <template v-if="item.proposal_date && !item.proposal_date.includes('0000')">
            <span>{{ formatDate(item.proposal_date || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span>
          </template>
        </template>
        <template #[`item.planned_relaunch_date`]="{item}">
          <template v-if="item.planned_relaunch_date && !item.planned_relaunch_date.includes('0000')">
            <span>{{ formatDate(item.planned_relaunch_date || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span>
          </template>
        </template>
        <template #[`item.relaunch_date`]="{item}">
          <template v-if="item.relaunch_date && !item.relaunch_date.includes('0000')">
            <span>{{ formatDate(item.relaunch_date || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span>
          </template>
        </template>

        <!-- status -->
<!--        <template #[`item.status.id`]="{item}">
          <v-chip
            v-if="item.status && item.status.id"
            @click="item.status_id !== $store.state.app.settings.manuscript_approuved_status && $can('approve','Manuscript')?openAccordDialog(item):null"
            small
            :color="item.status.color"
            :class="`${item.status.color}&#45;&#45;text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.status.name }}
          </v-chip>
        </template>-->

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                :disabled="isCompleted(item.publication)"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <!--              <v-list-item
                :to="{name:'manuscript-view',params:{id:item.id}}"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>{{ $t('Détails') }}</span>
                </v-list-item-title>
              </v-list-item>-->

              <v-list-item
                v-if="$can('edit','Manuscript')"
                @click="openDialog(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                  <span>{{ $t('Modifier') }}</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="item.status_id !== $store.state.app.settings.manuscript_approuved_status && $can('approve','Manuscript')"
                @click="openAccordDialog(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiCheckOutline }}
                  </v-icon>
                  <span>{{ $t('Accord Signature') }}</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="item.status_id !== $store.state.app.settings.manuscript_approuved_status && $can('delete','Manuscript')"
                @click="confirmDelete(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span><span>{{ $t('Supprimer') }}</span></span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
          </Row>
        </template>
      </v-data-table>
      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <rows-per-page :options="options" />
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="page"
              total-visible="6"
              :length="pageCount"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="deleteDialog"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 300 : '100%'"
    >
      <v-card>
        <v-card-title>{{ $t("Confirmer la suppression") }}</v-card-title>
        <v-divider />
        <v-card-text>{{ $t("Confirmez-vous de vouloir supprimer") }}</v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            small
            outlined
            @click="deleteDialog = false"
          >
            {{ $t("Non") }}
          </v-btn>
          <v-spacer />
          <v-btn
            small
            color="primary"
            :loading="loading"
            @click="deleteItem"
          >
            {{ $t("Oui") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      :light="$vuetify.theme.dark"
    >
      {{ snackBarMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="isSuccessful"
      :color="$vuetify.theme.currentTheme.success"
      :light="$vuetify.theme.dark"
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiClose,
  mdiMicrosoftExcel,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiPencilOutline,
  mdiCheckCircle,
  mdiCheckOutline,
  mdiCalendar,
  mdiImport
} from '@mdi/js'
import {onMounted, onUnmounted, ref, watch} from '@vue/composition-api'

// sidebar
import { avatarText, formatDate } from '@core/utils/filter'
import axios from '@axios'
import store from '@/store'
import ManuscriptForm from './ManuscriptForm.vue'
import itemStoreModule from './itemStoreModule'

import controller from './Controller'
import RowsPerPage from '@/components/RowsPerPage'
import SignatureForm from '@/views/apps/manuscript/SignatureForm'
import AccordForm from '@/views/apps/manuscript/AccordForm'
import HeaderFilters from '@/components/HeaderFilters'
import ExportExcel from '@/components/ExportExcel'
import ManuscriptPropositionForm from '@/views/apps/manuscript/ManuscriptPropositionForm'
import TableRow from '@/components/TableRow'
import Row from "@/components/Row";
import ManuscriptDateRelanceForm from "@/views/apps/manuscript/ManuscriptDateRelanceForm";
import ManuscriptImportForm from "@/views/apps/manuscript/ManuscriptImportForm.vue";

export default {
  components: {
    ManuscriptImportForm,
    ManuscriptDateRelanceForm,
    Row,
    TableRow,
    ManuscriptPropositionForm,
    ExportExcel,
    HeaderFilters,
    AccordForm,
    SignatureForm,
    RowsPerPage,
    ManuscriptForm,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-manuscript'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, itemStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      saved,
      item,
      items,
      downloadDialog,
      downloadObject,
      signatureDialog,
      accordDialog,
      tableColumns,
      filters,
      searchQuery,
      payFilter,
      sourceFilter,
      statusFilter,
      totalItems,
      loading,
      options,
      manuscriptTotalLocal,
      selectedRows,
      select,
      isFormActive,
      isPropositionActive,
      snackBarMessage,
      isSnackbarBottomVisible,
      isSuccessful,
      isRelanceActive,
      openRelanceDialog,
      openDialog,
      openPropositionDialog,
      openSignatureDialog,
      openAccordDialog,
      deleteItem,
      deleteDialog,
      confirmDelete,
      fetchItems,
      resolveManuscriptRoleVariant,
      resolveManuscriptRoleIcon,
      resolveManuscriptStatusVariant,
      resolveManuscriptStatusName,
      resolveManuscriptTotalIcon,
    } = controller()

    const superiors = ref([])
    const assistants = ref([])
    const isFormImportActive = ref(false)

    const payOptions = [
      { title: 'Admin', value: 'admin' },
      { title: 'Manuscript', value: 'manuscript' },
      { title: 'Editor', value: 'editor' },
      { title: 'Maintainer', value: 'maintainer' },
      { title: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { title: 'Basic', value: 'basic' },
      { title: 'Company', value: 'company' },
      { title: 'Enterprise', value: 'enterprise' },
      { title: 'Standard', value: 'standard' },
    ]

    const statusOptions = [
      { title: 'Actif', value: 1 },
      { title: 'Inactif', value: 0 },
    ]

    /* store
      .dispatch('app-manuscript/fetchCountries', { per_page: 500 }) */
    /* store
      .dispatch('app-manuscript/fetchSources', { per_page: 500 }) */
    /* store
      .dispatch('app/fetchGenres', { per_page: 500 }) */
    /* store
      .dispatch('app-manuscript/fetchFormats', { per_page: 500, type: 'manuscript' }) */
    /* store
      .dispatch('app-manuscript/fetchStatuses', { per_page: 500, status_type: 'manuscript' }) */

    axios
      .post('/particular_case/list', { page: 1000 })
      .then(response => {
        //store.state['app-manuscript/countries'] = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'particular_case.label')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data
          filters.value[indexH].fields[0].items = response.data
        }
      }).catch(error => console.log(error))


    axios
      .get('/countries', { params: { per_page: 500, type: 'manuscript' } })
      .then(response => {
        store.state['app-manuscript/countries'] = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'country.full_name')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data.data
        }
      }).catch(error => console.log(error))
    axios
      .get('/statuses', { params: { per_page: 500, type: 'manuscript' } })
      .then(response => {
        store.state['app-manuscript/statuses'] = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'status')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data.data
        }
      }).catch(error => console.log(error))
    axios
      .get('/sources', { params: { per_page: 500, type: 'manuscript' } })
      .then(response => {
        store.state['app/sources'] = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'source.source_name')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data.data
        }
        const indexO = filters.value.findIndex(ele => ele.value === 'origin.source_name')
        if (indexO > 0) {
          filters.value[indexO].filter.values = response.data.data
        }
      }).catch(error => console.log(error))
    axios
      .get('/formats', { params: { per_page: 500, type: 'manuscript' } })
      .then(response => {
        store.state['app-manuscript/formats'] = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'format.name')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data.data
        }
      })
      .catch(error => console.log(error))
    axios.get('/genres', { params: { per_page: 500, type: 'manuscript' } })
      .then(response => {
        store.state['app/genres'] = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'genre.label')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data.data
        }
      })
      .catch(error => console.log(error))

    axios
      .get('/listWorkers', {
        params: {
          term: '', type: 'manuscript', rowsPerPage: 200,
        },
      })
      .then(response => {
        for (let i = 0; i < response.data.length; i++) {
          const u = response.data[i]
          const indexU = assistants.value.findIndex(el => el.id === u.id)
          if (indexU < 0) {
            assistants.value.push(u)
          }
        }
        const indexH = filters.value.findIndex(ele => ele.value === 'assistant_lecture.code')
        if (indexH > 0) {
          filters.value[indexH].filter.values = assistants.value
        }
      })

    filters.value = tableColumns

    const windowHeight = ref(window.innerHeight)
    const tableHeight = ref(window.innerHeight - 250)
    window.addEventListener('resize', () => {
      windowHeight.value = window.innerHeight
    })
    watch(windowHeight, () => {
      tableHeight.value = windowHeight.value - 250
    })
    const getParticularCase = (page = 1) => {
      store.state.particularCase.field.paginate = 1000
      store.dispatch("particularCase/getParticularCasesList",
        {
          page: page,
          field: store.state.particularCase.field
        }).then(() => {
      }).catch(error => {
        console.log(error)
      })
    }

    onMounted(getParticularCase(store.getters["particularCase/current_page"]))
    return {
      tableHeight,
      saved,
      item,
      items,
      downloadDialog,
      downloadObject,
      superiors,
      filters,
      tableColumns,
      searchQuery,
      payFilter,
      sourceFilter,
      statusFilter,
      totalItems,
      payOptions,
      planOptions,
      statusOptions,
      loading,
      options,
      accordDialog,
      signatureDialog,
      manuscriptTotalLocal,
      isPropositionActive,
      isFormActive,
      selectedRows,
      select,
      snackBarMessage,
      isSnackbarBottomVisible,
      isSuccessful,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      openDialog,
      isRelanceActive,
      openRelanceDialog,
      openPropositionDialog,
      openSignatureDialog,
      openAccordDialog,
      deleteItem,
      deleteDialog,
      confirmDelete,
      avatarText,
      formatDate,
      resolveManuscriptRoleVariant,
      resolveManuscriptRoleIcon,
      resolveManuscriptStatusVariant,
      resolveManuscriptStatusName,
      resolveManuscriptTotalIcon,
      isFormImportActive,
      fetchItems,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiMicrosoftExcel,
        mdiPlus,
        mdiClose,
        mdiExportVariant,
        mdiAccountOutline,
        mdiPencilOutline,
        mdiCheckCircle,
        mdiCheckOutline,
        mdiCalendar,
        mdiImport,
      },
    }
  },
}
</script>
<style lang="scss">
  /*table {

    background-color: white;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
    display: block;
    overflow-x: scroll;
  }
  .v-data-table td, th {
    margin: 0;
    border: 1px solid grey;
    white-space: nowrap;
    border-top-width: 0px;
  }

  .v-data-table thead tr:first-child
  }
  .fixed-column {
    position: absolute;
    width: 5em;
    left: 0;
    top: auto;
    border-top-width: 1px;
    margin-top: -1px;
  }*/
</style>
<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
